<script>
    import Layout from "@/router/layouts/template-pemohon";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    import axios from "axios";
	import Swal from "sweetalert2"
    import $ from "jquery"
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Profile",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "Dashboard",
                items: [
                    {
                        text: "Dashboards",
                        active: true,
                    },
                    
                ],        
                axiosCatchError: null,
				session: JSON.parse(localStorage.session),
                profile: [],
                data_profile_perusahaan: [],
                data_pemegang_saham: [],
                data_penanggung_jawab: [],
                data_legalitas: [],
                data_rptka_negara: [],
                data_rptka_lokasi: [],
                data_data_dni: [],
                data_data_checklist: [],
                data_data_proyek: [],
            };
        },
        
        mounted() {
            let self = this;
            axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + "auth/profile", {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            }).then(function (response) {
                var response_data = response.data;
				var response_data_fix = response_data.data;
				if(response_data.meta.code == 200){
					self.profile = response_data_fix;
                    console.log(response_data_fix)
                    var datanibperusahaan = response_data_fix.perusahaan_json.responinqueryNIB.dataNIB;
						
                    var clear_data_perusahaan = {};
                    $.each(datanibperusahaan, function (index, val) {
                        if (typeof val === 'string' || val instanceof String) {
                            clear_data_perusahaan[index] = val;
                        }
                    });
                    self.data_profile_perusahaan = clear_data_perusahaan;
                    self.data_pemegang_saham = datanibperusahaan.pemegang_saham;
                    self.data_penanggung_jawab = datanibperusahaan.penanggung_jwb;
                    self.data_legalitas = datanibperusahaan.legalitas;
                    self.data_rptka_negara = datanibperusahaan.data_rptka.rptka_negara;
                    self.data_rptka_lokasi = datanibperusahaan.data_rptka.rptka_lokasi;
                    self.data_data_dni = datanibperusahaan.data_dni;
                    self.data_data_checklist = datanibperusahaan.data_checklist;
                    self.data_data_proyek = datanibperusahaan.data_proyek;

					Swal.close();
				}else{
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: response_data_fix.data.message
					});
				}
			}).catch(e => {
                console.log(e);
				// this.axiosCatchError = e.response.data.data
				Swal.close()
			})
        },
		
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title"/>
		<div class="row">
            <div class="col-lg-12">
                <div class="card">
					<div class="card-header top-radius-15 bg-success"><h4 class="my-0 text-center text-white"><b>Profile</b></h4></div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 my-3">
                                <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                                    <b-tab active>
                                        <template v-slot:title>
                                            <span class="d-inline-block d-sm-none">
                                                <i class="fas fa-home"></i>
                                            </span>
                                            <span class="d-none d-sm-inline-block">Data Akun</span>
                                        </template>
                                        <div class="row">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <b-form-group class="mb-3" label="Nama Akun">
                                                        <b-form-input type="text" v-model="session.name"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-group class="mb-3" label="Email Akun">
                                                        <b-form-input type="text" v-model="session.email"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-group class="mb-3" label="Password">
                                                        <b-form-input type="text"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-group class="mb-3" label="Konfirmasi Password">
                                                        <b-form-input type="text"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <b-button type="submit" variant="success" style="margin-top: 27px" class="w-100"><i class="fa fa-save"></i> Simpan</b-button>
                                        </div>
                                    </b-tab>
                                    <b-tab>
                                        <template v-slot:title>
                                            <span class="d-inline-block d-sm-none">
                                                <i class="far fa-user"></i>
                                            </span>
                                            <span class="d-none d-sm-inline-block">Perbarui Perusahaan</span>
                                        </template>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <b-form-group class="mb-3" label="NIB Perusahaan" label-for="formrow-nama-sub-perizinan-input">
                                                    <b-form-input id="formrow-nama-sub-perizinan-input" type="text"></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-8">
                                                <b-button type="submit" variant="success" style="margin-top: 27px"><i class="fa fa-save"></i> Simpan</b-button>
                                            </div>
                                            <div class="col-md-12">
                                                Pembaruan Terakhir : 21 Desember 2022 15:00
                                            </div>
                                            
                                        </div>
                                    </b-tab>
                                    <b-tab>
                                        <template v-slot:title>
                                            <span class="d-inline-block d-sm-none">
                                                <i class="far fa-envelope"></i>
                                            </span>
                                            <span class="d-none d-sm-inline-block">Data Perusahaan</span>
                                        </template>
                                        <b-tabs pills content-class="p-3 text-muted">
                                            <b-tab active class="border-0">
                                                <template v-slot:title>
                                                    <span class="d-inline-block d-sm-none">
                                                        <i class="fas fa-home"></i>
                                                    </span>
                                                    <span class="d-none d-sm-inline-block">Profile Perusahaan</span>
                                                </template>
                                                <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                    <tbody>
                                                        <tr v-for="(value_data_profile_perusahaan, key_data_profile_perusahaan) in data_profile_perusahaan" :key="key_data_profile_perusahaan">
                                                            <th width="350">{{key_data_profile_perusahaan}}</th>
                                                            <td>{{value_data_profile_perusahaan}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </b-tab>
                                            <b-tab class="border-0">
                                                <template v-slot:title>
                                                    <span class="d-inline-block d-sm-none">
                                                        <i class="fas fa-home"></i>
                                                    </span>
                                                    <span class="d-none d-sm-inline-block">Data Investasi</span>
                                                </template>
                                                Data Tidak Tersedia
                                            </b-tab>
                                            
                                            <b-tab class="border-0">
                                                <template v-slot:title>
                                                    <span class="d-inline-block d-sm-none">
                                                        <i class="fas fa-home"></i>
                                                    </span>
                                                    <span class="d-none d-sm-inline-block">Pemegang Saham</span>
                                                </template>
                                                
                                                <div class="accordion" id="accordionExample-data_pemegang_saham">
                                                    <div class="accordion-item" v-for="(value_data_pemegang_saham, key_data_pemegang_saham) in data_pemegang_saham" :key="key_data_pemegang_saham">
                                                        <h2 class="accordion-header">
                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#pemegang_saham-'+key_data_pemegang_saham+1" aria-expanded="true" :aria-controls="'pemegang_saham-'+ key_data_pemegang_saham+1">
                                                                Pemegang Saham #{{ key_data_pemegang_saham+1 }}
                                                            </button>
                                                        </h2>
                                                        <div :id="'pemegang_saham-'+key_data_pemegang_saham+1" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                    <tbody>
                                                                        <tr v-for="(value_data_pemegang_saham_data, key_data_pemegang_saham_val) in value_data_pemegang_saham" :key="key_data_pemegang_saham_val">
                                                                            <th width="350">{{key_data_pemegang_saham_val}}</th>
                                                                            <td>{{value_data_pemegang_saham_data}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </b-tab>
                                            
                                            <b-tab class="border-0">
                                                <template v-slot:title>
                                                    <span class="d-inline-block d-sm-none">
                                                        <i class="fas fa-home"></i>
                                                    </span>
                                                    <span class="d-none d-sm-inline-block">Pemegang Saham</span>
                                                </template>
                                                
                                                <div class="accordion" id="accordionExample-data_penanggung_jawab">
                                                    <div class="accordion-item" v-for="(value_data_penanggung_jawab, key_data_penanggung_jawab) in data_penanggung_jawab" :key="key_data_penanggung_jawab">
                                                        <h2 class="accordion-header">
                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#penanggung_jawab-'+key_data_penanggung_jawab+1" aria-expanded="true" :aria-controls="'penanggung_jawab-'+ key_data_penanggung_jawab+1">
                                                                Pemegang Saham #{{ key_data_penanggung_jawab+1 }}
                                                            </button>
                                                        </h2>
                                                        <div :id="'penanggung_jawab-'+key_data_penanggung_jawab+1" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                    <tbody>
                                                                        <tr v-for="(value_data_penanggung_jawab_data, key_data_penanggung_jawab_val) in value_data_penanggung_jawab" :key="key_data_penanggung_jawab_val">
                                                                            <th width="350">{{key_data_penanggung_jawab_val}}</th>
                                                                            <td>{{value_data_penanggung_jawab_data}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </b-tab>

                                            <b-tab class="border-0">
                                                <template v-slot:title>
                                                    <span class="d-inline-block d-sm-none">
                                                        <i class="fas fa-home"></i>
                                                    </span>
                                                    <span class="d-none d-sm-inline-block">Legalitas</span>
                                                </template>
                                                
                                                <div class="accordion" id="accordionExample-data_legalitas">
                                                    <div class="accordion-item" v-for="(value_data_legalitas, key_data_legalitas) in data_legalitas" :key="key_data_legalitas">
                                                        <h2 class="accordion-header">
                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#legalitas-'+key_data_legalitas+1" aria-expanded="true" :aria-controls="'legalitas-'+ key_data_legalitas+1">
                                                                Legalitas #{{ key_data_legalitas+1 }}
                                                            </button>
                                                        </h2>
                                                        <div :id="'legalitas-'+key_data_legalitas+1" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                    <tbody>
                                                                        <tr v-for="(value_data_legalitas_data, key_data_legalitas_val) in value_data_legalitas" :key="key_data_legalitas_val">
                                                                            <th width="350">{{key_data_legalitas_val}}</th>
                                                                            <td>{{value_data_legalitas_data}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </b-tab>
                                            
                                            <b-tab class="border-0">
                                                <template v-slot:title>
                                                    <span class="d-inline-block d-sm-none">
                                                        <i class="fas fa-home"></i>
                                                    </span>
                                                    <span class="d-none d-sm-inline-block">RPTKA Jabatan</span>
                                                </template>
                                                Data Tidak Tersedia
                                            </b-tab>

                                            <b-tab class="border-0">
                                                <template v-slot:title>
                                                    <span class="d-inline-block d-sm-none">
                                                        <i class="fas fa-home"></i>
                                                    </span>
                                                    <span class="d-none d-sm-inline-block">RPTKA Negara</span>
                                                </template>
                                                
                                                <div class="accordion" id="accordionExample-data_rptka_negara">
                                                    <div class="accordion-item" v-for="(value_data_rptka_negara, key_data_rptka_negara) in data_rptka_negara" :key="key_data_rptka_negara">
                                                        <h2 class="accordion-header">
                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#rptka_negara-'+key_data_rptka_negara+1" aria-expanded="true" :aria-controls="'rptka_negara-'+ key_data_rptka_negara+1">
                                                                RPTKA Negara #{{ key_data_rptka_negara+1 }}
                                                            </button>
                                                        </h2>
                                                        <div :id="'rptka_negara-'+key_data_rptka_negara+1" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                    <tbody>
                                                                        <tr v-for="(value_data_rptka_negara_data, key_data_rptka_negara_val) in value_data_rptka_negara" :key="key_data_rptka_negara_val">
                                                                            <th width="350">{{key_data_rptka_negara_val}}</th>
                                                                            <td>{{value_data_rptka_negara_data}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </b-tab>

                                            <b-tab class="border-0">
                                                <template v-slot:title>
                                                    <span class="d-inline-block d-sm-none">
                                                        <i class="fas fa-home"></i>
                                                    </span>
                                                    <span class="d-none d-sm-inline-block">RPTKA Lokasi</span>
                                                </template>
                                                
                                                <div class="accordion" id="accordionExample-data_rptka_lokasi">
                                                    <div class="accordion-item" v-for="(value_data_rptka_lokasi, key_data_rptka_lokasi) in data_rptka_lokasi" :key="key_data_rptka_lokasi">
                                                        <h2 class="accordion-header">
                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#rptka_lokasi-'+key_data_rptka_lokasi+1" aria-expanded="true" :aria-controls="'rptka_lokasi-'+ key_data_rptka_lokasi+1">
                                                                RPTKA Lokasi #{{ key_data_rptka_lokasi+1 }}
                                                            </button>
                                                        </h2>
                                                        <div :id="'rptka_lokasi-'+key_data_rptka_lokasi+1" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                    <tbody>
                                                                        <tr v-for="(value_data_rptka_lokasi_data, key_data_rptka_lokasi_val) in value_data_rptka_lokasi" :key="key_data_rptka_lokasi_val">
                                                                            <th width="350">{{key_data_rptka_lokasi_val}}</th>
                                                                            <td>{{value_data_rptka_lokasi_data}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </b-tab>

                                            <b-tab class="border-0">
                                                <template v-slot:title>
                                                    <span class="d-inline-block d-sm-none">
                                                        <i class="fas fa-home"></i>
                                                    </span>
                                                    <span class="d-none d-sm-inline-block">Data DMI</span>
                                                </template>
                                                
                                                <div class="accordion" id="accordionExample-data_data_dni">
                                                    <div class="accordion-item" v-for="(value_data_data_dni, key_data_data_dni) in data_data_dni" :key="key_data_data_dni">
                                                        <h2 class="accordion-header">
                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#data_dni-'+key_data_data_dni+1" aria-expanded="true" :aria-controls="'data_dni-'+ key_data_data_dni+1">
                                                                Data DMI #{{ key_data_data_dni+1 }}
                                                            </button>
                                                        </h2>
                                                        <div :id="'data_dni-'+key_data_data_dni+1" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                    <tbody>
                                                                        <tr v-for="(value_data_data_dni_data, key_data_data_dni_val) in value_data_data_dni" :key="key_data_data_dni_val">
                                                                            <th width="350">{{key_data_data_dni_val}}</th>
                                                                            <td>{{value_data_data_dni_data}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </b-tab>

                                            <b-tab class="border-0">
                                                <template v-slot:title>
                                                    <span class="d-inline-block d-sm-none">
                                                        <i class="fas fa-home"></i>
                                                    </span>
                                                    <span class="d-none d-sm-inline-block">Data Khecklist</span>
                                                </template>
                                                
                                                <div class="accordion" id="accordionExample-data_data_checklist">
                                                    <div class="accordion-item" v-for="(value_data_data_checklist, key_data_data_checklist) in data_data_checklist" :key="key_data_data_checklist">
                                                        <h2 class="accordion-header">
                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#data_checklist-'+key_data_data_checklist+1" aria-expanded="true" :aria-controls="'data_checklist-'+ key_data_data_checklist+1">
                                                                Data Khecklist #{{ key_data_data_checklist+1 }}
                                                            </button>
                                                        </h2>
                                                        <div :id="'data_checklist-'+key_data_data_checklist+1" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                    <tbody>
                                                                        <tr v-for="(value_data_data_checklist_data, key_data_data_checklist_val) in value_data_data_checklist" :key="key_data_data_checklist_val">
                                                                            <th width="350">{{key_data_data_checklist_val}}</th>
                                                                            <td>{{value_data_data_checklist_data}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </b-tab>

                                            <b-tab class="border-0">
                                                <template v-slot:title>
                                                    <span class="d-inline-block d-sm-none">
                                                        <i class="fas fa-home"></i>
                                                    </span>
                                                    <span class="d-none d-sm-inline-block">Data Proyek</span>
                                                </template>
                                                
                                                <div class="accordion" id="accordionExample-data_data_proyek">
                                                    <div class="accordion-item" v-for="(value_data_data_proyek, key_data_data_proyek) in data_data_proyek" :key="key_data_data_proyek">
                                                        <h2 class="accordion-header">
                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#data_proyek-'+key_data_data_proyek+1" aria-expanded="true" :aria-controls="'data_proyek-'+ key_data_data_proyek+1">
                                                                Data Proyek #{{ key_data_data_proyek+1 }}
                                                            </button>
                                                        </h2>
                                                        <div :id="'data_proyek-'+key_data_data_proyek+1" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body">
                                                                <table class="table table-hover table-striped table-bordered table-sm mt-3">
                                                                    <tbody>
                                                                        <tr v-for="(value_data_data_proyek_data, key_data_data_proyek_val) in value_data_data_proyek" :key="key_data_data_proyek_val">
                                                                            <th width="350">{{key_data_data_proyek_val}}</th>
                                                                            <td>{{value_data_data_proyek_data}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </b-tab>

                                        </b-tabs>

                                    </b-tab>
                                    
                                </b-tabs>

                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
